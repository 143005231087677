import React, {useEffect, useRef} from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import './styles.css';
import {isValidColor} from './helper';

const surveyTypes = [
  {
    description: 'Alle Abteilungen nutzen denselben Fragebogen',
    value: 'divisions_use_same_survey',
  },
  {
    description: 'Alle Abteilungen haben einen eigenen Fragebogen zugeordnet bekommen',
    value: 'divisions_use_different_surveys',
  },
];

const emptyDivision = {
  divName: '',
  color: '',
};

const divisionColors = [
  '#ffd700', '#1717a1', '#008080', '#8b4513', '#228b22',
  '#b00b69', '#b8860b', '#4682b4', '#000080', '#9acd32',
  '#8b008b', '#000000', '#ffff00', '#7cfc00', '#8a2be2',
  '#00ff7f', '#dc143c', '#0000ff', '#f08080', '#ff00ff',
  '#1e90ff', '#dda0dd', '#ff1493', '#7fffd4', '#ffe4b5',
];

const App = () => {
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      surveyType: surveyTypes[0].value,
      surveyStart: '',
      surveyEnd: '',
      divisions: [
        {divName: 'Ärztlicher Dienst',color: '#ffd700'},
        {divName: 'Pflegedienst',color: '#1717a1'},
        {divName: 'Verwaltung',color:'#008080'},
        {divName: 'Sekretariate',color: '#8b4513'},
        {divName: 'Rezeptionen und Haustechnik',color:  '#228b22'},
        {divName: 'Führungskraft (Chefärzte, Stationsleitungen und Stellvertretungen, Abteilungsleitungen und Stellvertretungen)',color: 
        '#b00b69'},
        {divName: 'Medizinisch-technischer Dienst (alle Mitarbeitenden der Therapiebereiche)rwaltung',color: '#b8860b'},
        {divName: 'Sonstiges Personalretariate',color: '#4682b4'},
       ]
    },
  });

  const { fields: divisions, append, remove } = useFieldArray({
    control,
    name: 'divisions'
  });

  const storedDivs = useRef(divisions);
 
  useEffect(() => {
    if (divisions.length > storedDivs.current.length) {
      const copiedDivisions = [...divisions];
      const index = copiedDivisions.length - 1;
      const color = divisionColors[index] ? divisionColors[index] : '';
      setValue(`divisions.${index}.color`, color);
      storedDivs.current = copiedDivisions;
    }
  }, [divisions, setValue]);


  const formValues = watch();

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label>Art der Befragung</label>
          {surveyTypes.map(st => (
            <div key={st.description} className="radio-group">
              <input
                type="radio"
                name="survey_type"
                value={st.value}
                {...register('surveyType', {required: true})}
              />
              <label>{st.description}</label>
            </div>
          ))}
        </div>
        <div className="form-control">
          <label>Anfang Befragungszeitraum</label>
          <input
            className={errors.surveyStart ? 'hasError' : ''}
            type="text"
            name="surveyStart"
            {...register('surveyStart')}
          />
        </div>
        <div className="form-control">
          <label>Ende Befragungszeitraum</label>
          <input
            className={errors.surveyEnd ? 'hasError' : ''}
            type="text"
            name="surveyEnd"
            {...register('surveyEnd')}
          />
        </div>
        <div className="form-control">
          <label>Bereiche</label>
          <button
            style={{marginBottom: '16px'}}
            type="button"
            onClick={() => append(emptyDivision)}>
            Bereich hinzufügen
          </button>
          <div className="box" style={{gap: 5}}>
            {divisions.map((division, index) => (
              <div key={division.id} className="box-in-box">
                <button
                  style={{maxWidth: 40, alignSelf: 'end'}}
                  type="button"
                  onClick={() => remove(index)}
                >X</button>
                <div className="form-control" style={{flexDirection: 'row'}}>
                  <label>Name</label>
                  <input
                    className={errors.divisions && errors.divisions[index] && errors.divisions[index].divName ? 'hasError' : ''}
                    type="text"
                    name={`division_${index}_divName`}
                    {...register(`divisions.${index}.divName`, {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                </div>
                <div className="form-control" style={{flexDirection: 'row'}}>
                  <label>Farbe (in hex)</label>
                  <input
                    className={errors.divisions && errors.divisions[index] && errors.divisions[index].color ? 'hasError' : ''}
                    style={{backgroundColor: divisions[index].color}}
                    type="text"
                    name={`division_${index}_color`}
                    {...register(`divisions.${index}.color`, {
                      required: true,
                      minLength: 4,
                      maxLength: 7,
                      pattern: {
                        value: /^#([0-9A-F]{3}){1,2}$/i,
                        message: 'Falsches Farbformat'
                      }
                    })}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {divisions && divisions.length ? (
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 12}}>Farbpalette</div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', gap: 5}}>
              {formValues.divisions.map(d => {
                const isValid = isValidColor(d.color);
                const backgroundColor = isValid ? d.color : 'black';
                return <div style={{backgroundColor, width: 25, height: 25}}></div>
              })}
            </div>
          </div>
        ) : null}
        {!isValid && <span>{Object.keys(errors)}</span>}
        <button
          style={{marginTop: 20}}
          onClick={()  => {
            navigator.clipboard.writeText(JSON.stringify(formValues, null, 2));
          }}>JSON kopieren</button>
        <textarea
          style={{width: '100%', height: 500, marginTop: 25}}
          value={JSON.stringify(formValues, null, 2)}
          onChange={() => {}}
        />
      </form>
    </div>
  );
}

export default App;
